<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 20 20"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.52038 2.14601C6.29229 2.08438 7.02507 1.78077 7.61438 1.27841C8.27997 0.711518 9.12569 0.400177 9.99998 0.400177C10.8743 0.400177 11.72 0.711518 12.3856 1.27841C12.9749 1.78077 13.7077 2.08438 14.4796 2.14601C15.3513 2.21568 16.1697 2.59356 16.7881 3.21193C17.4064 3.83029 17.7843 4.64869 17.854 5.52041C17.9152 6.29201 18.2188 7.02521 18.7216 7.61441C19.2885 8.28 19.5998 9.12572 19.5998 10C19.5998 10.8743 19.2885 11.72 18.7216 12.3856C18.2192 12.9749 17.9156 13.7077 17.854 14.4796C17.7843 15.3513 17.4064 16.1697 16.7881 16.7881C16.1697 17.4065 15.3513 17.7843 14.4796 17.854C13.7077 17.9156 12.9749 18.2193 12.3856 18.7216C11.72 19.2885 10.8743 19.5998 9.99998 19.5998C9.12569 19.5998 8.27997 19.2885 7.61438 18.7216C7.02507 18.2193 6.29229 17.9156 5.52038 17.854C4.64866 17.7843 3.83026 17.4065 3.2119 16.7881C2.59353 16.1697 2.21565 15.3513 2.14598 14.4796C2.08435 13.7077 1.78074 12.9749 1.27838 12.3856C0.711488 11.72 0.400146 10.8743 0.400146 10C0.400146 9.12572 0.711488 8.28 1.27838 7.61441C1.78074 7.0251 2.08435 6.29232 2.14598 5.52041C2.21565 4.64869 2.59353 3.83029 3.2119 3.21193C3.83026 2.59356 4.64866 2.21568 5.52038 2.14601V2.14601ZM14.4484 8.44841C14.667 8.22209 14.7879 7.91896 14.7852 7.60433C14.7825 7.28969 14.6563 6.98872 14.4338 6.76623C14.2113 6.54374 13.9103 6.41753 13.5957 6.4148C13.281 6.41207 12.9779 6.53302 12.7516 6.75161L8.79998 10.7032L7.24838 9.15161C7.02206 8.93302 6.71893 8.81207 6.4043 8.8148C6.08966 8.81753 5.78869 8.94374 5.5662 9.16623C5.34371 9.38872 5.2175 9.68969 5.21477 10.0043C5.21203 10.319 5.33299 10.6221 5.55158 10.8484L7.95158 13.2484C8.17661 13.4734 8.48178 13.5998 8.79998 13.5998C9.11818 13.5998 9.42335 13.4734 9.64838 13.2484L14.4484 8.44841V8.44841Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 20 20"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.05909 1.05602C8.71134 -0.352008 11.1414 -0.352008 12.7937 1.05602C13.1814 1.38642 13.6634 1.58609 14.1712 1.62661C16.3351 1.79929 18.0535 3.51764 18.2262 5.68158C18.2667 6.18935 18.4664 6.67139 18.7968 7.05909C20.2048 8.71134 20.2048 11.1414 18.7968 12.7937C18.4664 13.1814 18.2667 13.6634 18.2262 14.1712C18.0535 16.3351 16.3351 18.0535 14.1712 18.2262C13.6634 18.2667 13.1814 18.4664 12.7937 18.7968C11.1414 20.2048 8.71134 20.2048 7.05909 18.7968C6.67139 18.4664 6.18935 18.2667 5.68158 18.2262C3.51764 18.0535 1.79929 16.3351 1.62661 14.1712C1.58609 13.6634 1.38642 13.1814 1.05602 12.7937C-0.352008 11.1414 -0.352008 8.71134 1.05602 7.05909C1.38642 6.67139 1.58609 6.18935 1.62661 5.68158C1.79929 3.51764 3.51764 1.79929 5.68158 1.62661C6.18935 1.58609 6.67139 1.38642 7.05909 1.05602ZM11.4965 2.57825C10.5917 1.80725 9.26106 1.80725 8.35633 2.57825C7.6483 3.18163 6.76798 3.54627 5.84068 3.62027C4.65575 3.71483 3.71483 4.65575 3.62027 5.84068C3.54627 6.76798 3.18163 7.6483 2.57825 8.35633C1.80725 9.26106 1.80725 10.5917 2.57825 11.4965C3.18163 12.2045 3.54627 13.0848 3.62027 14.0121C3.71483 15.197 4.65575 16.138 5.84068 16.2325C6.76798 16.3065 7.6483 16.6712 8.35633 17.2745C9.26106 18.0455 10.5917 18.0455 11.4965 17.2745C12.2045 16.6712 13.0848 16.3065 14.0121 16.2325C15.197 16.138 16.138 15.197 16.2325 14.0121C16.3065 13.0848 16.6712 12.2045 17.2745 11.4965C18.0455 10.5917 18.0455 9.26106 17.2745 8.35633C16.6712 7.6483 16.3065 6.76798 16.2325 5.84068C16.138 4.65575 15.197 3.71483 14.0121 3.62027C13.0848 3.54627 12.2045 3.18163 11.4965 2.57825ZM13.6335 7.21929C14.024 7.60981 14.024 8.24297 13.6335 8.6335L9.6335 12.6335C9.24297 13.024 8.60981 13.024 8.21929 12.6335L6.21929 10.6335C5.82876 10.243 5.82876 9.60981 6.21929 9.21929C6.60981 8.82876 7.24297 8.82876 7.6335 9.21929L8.92639 10.5122L12.2193 7.21929C12.6098 6.82876 13.243 6.82876 13.6335 7.21929Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconBadgeCheck",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
